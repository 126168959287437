.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  max-width: 660px;
  width: 100%;
  border-radius: 4px;
  .modal-body {
    position: relative;
    outline: none !important;
    max-height: 100vh;
    overflow: auto;
    .close-btn {
        position: absolute;
        right: 10px;
        top: 13px;
        height: 30px;
        width: 30px;
        color: #696E79;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        cursor: pointer;
    }
    .modal-title {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      color: #282c3f;
      padding: 14px 0px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 10px;
    }
  }
}
.schedule-modal-main {
  padding: 0px 40px;
  padding-bottom: 30px;
  .form-row-main {
    margin-bottom: 22px;
  }
  .btn-row {
    display: flex;
    justify-content: center;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.d-flex {
  display: flex;
  align-items: center;
  width: fit-content !important;
}
.pr-5 {
  padding-right: 5px;
}
.TableListingwithTabs {
  box-shadow: none !important;
  margin: 0 !important;
}
.ImgList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 300px;
  width: 300px;
  img {
    width: 125px;
    height: 125px;
    object-fit: contain;
    margin: 10px;
  }
}
