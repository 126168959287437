.profile-box {
    width: 100% !important;
    cursor: pointer;
}

.profile-box p {
    padding: 0 1rem;
    color: rgb(23, 24, 50, .6);
}

.search-box .search-input {
    position: relative;
}

.search-box .search-input span {
    position: absolute;
    top: 12px;
    right: 20px;
}

.noti-box {
    border-right: 2px solid #c5c5c5;
}

.noti-box .not-img {
    width: 30px;
    margin-right: 10px;
}

.header-box-main .inner-box-main-area .right-side-inner-box .search-box input {
    width: 400px;
}

.noti-box .not-img {
    position: relative;
    cursor: pointer;
}

.noti-box .not-img span {
    position: absolute;
    background-color: #203156;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    top: 0;
    right: 10px;
}


@media (min-width:768px) and (max-width:1300px) {
    .header-box-main .inner-box-main-area .right-side-inner-box .search-box input {
        width: 150px;
        margin-left: 10px;
    }
}

@media screen and (max-width:767px) {
    .header-box-main .inner-box-main-area .right-side-inner-box .search-box input {
        width: 100%;
    }
}