.header-box-main{
    padding: 0px 30px;
    background: #fff;
    .inner-box-main-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 0px;
        border-bottom: 1px solid #f4f4f4;
        .inner-text-main {
            font-weight: 600;
            font-size: 22px;
            line-height: 28px;
            color: #282C3F;
        }
        .right-side-inner-box {
            display: flex;
            align-items: center;
            column-gap: 20px;
            margin-left: auto;
            .search-box{
                position: relative;
                width: 100%;
                input{
                    background: #f0f0f0;
                    border-radius: 8px;
                    outline: none !important;
                    border: none;
                    padding: 15px 20px;
                    width: 100%;
                    box-sizing: border-box;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #696E79;
                    padding-right: 50px;
                }
                i{
                    position: absolute;
                    right: 20px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    color: rgba(6, 22, 36, 0.5);
                    font-size: 17px;
                }
            }
        }
    }
}

.not-bell {
    font-size: 20px;
    color: rgba(6, 22, 36, 0.5);
    cursor: pointer;
}

.mail-box{
    font-size:20px;
    color: rgba(6, 22, 36, 0.5);
    cursor: pointer;
}

.bredcumbs-area {
    padding: 15px 30px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .inner-area{
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #696E79;
        cursor: pointer;
    }
    .highlighted {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #171832;
    }
    
}