.main-boxed {
    margin-left: 251px;
}

.main-page-area {
    .main-boxed {
        margin-left: 70px;
    }
}


.page-start {
    padding: 1rem 20px;
    background: #fafbfc;

    .white-box-main {
        background: #fafbfc;
        border-radius: 10px;
        padding: 10px 10px;
        font-size: 16px;
    }
}