.first-row-for-customers-page {
    padding: 20px 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-side-menu-filter{
        .MuiButton-root {
            padding: 0 !important;
            height: auto;
            display: flex;
            align-items: center;
            column-gap: 10px;
            text-transform: capitalize;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #282C3F;
        }
    }
    .right-side-btns-main {
        display: flex;
        align-items: center;
        column-gap: 20px;
        .without-background {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #282C3F;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}

// ---Detail-page-main---//

.detail-page-boxed-main {
    padding: 10px 0px;
    .inner-box-main{
        display: flex;
        .left-side-box-main {
            padding: 0px 20px;
            width: 70%;
        }
        .right-side-box-main {
            width: 30%;
            border-left: 1px solid #e7e7e7cc;
            padding: 0px 20px;
        }
    }
}


.customer-name {
    font-weight: 600;
    font-size: 20px;
    color: #282C3F;
    margin-bottom: 10px;
}
.customr-address-details {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #696E79;
    margin-bottom: 20px;
}

.placeholder-box-main {
    border: 1px solid #DADADA;
    border-radius: 10px;
    padding: 40px 0px;
    text-align: center;
}

.small-text-main {
    font-weight: 400;
    font-size: 18px;
    color: #696E79;
    margin-top: 30px;
}

.data-box-main{
    .gray-box-main {
        background: #FAFAFA;
        border-radius: 8px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 20px 0px;
        .comman-col-main {
            padding: 0px 20px;
            &:nth-child(2){
                border-left: 1px solid #ccc;
                border-right: 1px solid #ccc;
            }
            .com-text {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #282C3F;
                margin-bottom: 10px;
            }
            .last-order{
                text-decoration-line: underline;
            }
            .com-labal-main {
                font-weight: 600;
                font-size: 15px;
                line-height: 19px;
                color: #282C3F;
                margin-bottom: 12px;
            }
            .from-store {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #696E79;
            }
        }
    }
}

.order-box-main-area {
    margin-top: 30px;
    border: 1px solid #DADADA;
    border-radius: 10px;
    padding: 15px 20px;
    box-sizing: border-box;
    .commna-box-main{
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #f5f2f2;
        .inside-first-row-main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            .order-left-side-area{
                .order-title {
                    font-weight: 500;
                    font-size: 18px;
                    color: #282C3F;
                    margin-bottom: 15px;
                }
                .sub-row-main {
                    display: flex;
                    align-items: center;
                    column-gap: 20px;
                    .order-number {
                        font-weight: 500;
                        font-size: 15px;
                        text-decoration-line: underline;
                        color: #696E79;
                    } 
                }
            }
            .order-right-side-area {
                font-weight: 500;
                font-size: 14px;
                text-align: right;
                color: #696E79;
            }
        }
        .third-row-inside {
            display: flex;
            align-items: center;
            column-gap: 20px;
            .left-img-box{
                img{
                    width: 60px;
                }
            }
            .right-side-title{
                .text-title {
                    font-weight: 500;
                    font-size: 14px;
                    text-transform: capitalize;
                    color: #282C3F;
                    margin-bottom: 5px;
                }
                .size-groups {
                    font-size: 13px;
                    text-transform: capitalize;
                    color: #696E79;
                }
            }
        }
    }
}


.full-filed {
    font-weight: 400;
    font-size: 15px;
    background: rgba(5, 182, 81, 0.05);
    padding: 6px 30px;
    border-radius: 40px;
    color: #05B651;
}

.second-row-inside {
    font-weight: 500;
    font-size: 15px;
    color: #696E79;
    margin-bottom: 14px;
}

.comman-box-main-for-preview-detial {
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}

.first-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.comman-labal {
    font-weight: 500;
    font-size: 20px;
    color: #282C3F;
}

.edit-icon {
    cursor: pointer;
    font-size: 20px;
    color: #a5a5a5;
}

.sub-details-main{
    .comman-row {
        display: flex;
        align-items: center;
        column-gap: 30px;
        margin-bottom: 14px;
        &:last-child{
            margin-bottom: 0px;
        }
        .sub-title {
            width: 20%;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #282C3F;
        }
        .sub-des {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #696E79;
        }
    }
}

.mail-id {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #696E79;
    margin-bottom: 10px;
}

.mobile-num{
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #696E79;
    margin-bottom: 20px;
}
.has-account {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #696E79;
}

.manage-add {
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer;
}

.address-text {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 24px;
    color: #696E79;
    font-weight: 500;
}

.add-address-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #282C3F;
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
}



.first-row-order-main {
    display: flex;
    column-gap: 16px;
    border-bottom: 1px solid #ccc;
    align-items: center;
    .left-side-box {
        width: 10%;
        text-align: center;
    }
    .right-side-box {
        width: 90%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        align-items: center;
        grid-column-gap: 20px;
        box-sizing: border-box;
        .order-boxed {
            background: #f5f5f5;
            border-radius: 8px 8px 0px 0px;
            padding: 20px 20px;
            cursor: pointer;
            .text-title {
                font-weight: 600;
                font-size: 15px;
                color: #282C3F;
                margin-bottom: 5px;
            }
            .comman-numbers {
                font-weight: 400;
                font-size: 15px;
                color: #696E79;
            }
        }
    }
}

.second-row-order-main {
    align-items: center;
    display: flex;
    padding: 0px 10px;
    column-gap: 20px;
    .text-links {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #282C3F;
        opacity: 0.5;
        cursor: pointer;
        padding: 15px 0px;
    }
    .active{
        opacity: 1;
    }
}
.btn-paid {
    padding: 6px 10px;
    width: 84px;
    text-align: center;
    background: #E7F0EB;
    color: #05B651 !important;
    border-radius: 26px;}

.super-app.negative {
    .MuiDataGrid-cellContent
    {
        padding: 6px 10px;
        width: 84px;
        text-align: center;
        background: #E7F0EB;
        color: #05B651 !important;
        border-radius: 26px;
    }
}
.super-app.positive {
    .MuiDataGrid-cellContent
    {
        padding: 6px 10px;
        width: 84px;
        text-align: center;
        background: #FFFAF4 !important;
        color: #FF941A !important;
        border-radius: 26px;
    }
}
.scheduledPickup  {
    button {
        background-color: #282C3F;
        color: #FFF;
        padding: 10px 10px;
        cursor: pointer;
    }
}
.d-flex {
    display: flex;
}
.fontStyle {
    color: #696E79;
    font-size: 12px;
    line-height: 20px;
}
.headerFontStyle {
    color: #061624 !important;
    font-size: 13px;
    font-weight: 500 !important;
    line-height: 22px;
    border-bottom: 0.578379px solid #696E79;
}
.boldFont {
    color: #282C3F !important;
    font-weight: 400;
    text-transform: capitalize;
}
.success-color {
    color: #05B651 !important;
    cursor: pointer;
}
.pendingStatus {
    padding: 6px 10px;
    width: 84px;
    text-align: center;
    background: #FFFAF4 !important;
    color: #FF941A !important;
    border-radius: 26px;
}
.cancelStatus {
    background: #F4F4F5;
    padding: 6px 10px;
    width: 84px;
    text-align: center;
    color: #282C3F !important;
    border-radius: 26px;
}
.unshippedStatus {
    background: #FEF5F5;
    padding: 6px 10px;
    width: 84px;
    text-align: center;
    color: #F13131 !important;
    border-radius: 26px;
}
.pr-10 {
    padding-right: 10px;
}
.tableContainer {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    thead {
        tr > th {
            text-align: start !important;
            color: #061624 !important;
            font-size: 13px;
            font-weight: 600 !important;
            line-height: 22px;
            padding: 10px;
        }
    }
    tbody {
        tr > td {
            padding: 10px 10px;
            vertical-align: middle;
            font-size: 14px;
            font-weight: 400;
        }
    }
    .actiondisplay {
        tr > td {
            vertical-align: middle;
            padding: 10px 10px 10px 10px !important;
        }
    }
    .outlineBtn {
        padding: 3px 0px;
        border: 1px solid #DEDEDE;
        text-align: center;
        .fa-caret-down {
            padding-left: 10px;
        }
       }
       .outlineButton {
        padding: 5px 30px;
        border:1px solid #282C3F;;
        text-align: center;
        margin-bottom: 10px !important;
        color: #282C3F;
        background: #FFFFFF;
        cursor: pointer;
    }
    .outlineSelectedBtn {
        background-color: #282C3F !important;
        color: #FFF !important;
    }
}
.tableContainer { border-collapse: collapse;
    border-spacing: 15px;}
tr { border-top: 0.578379px solid #F4F4F5; 
}
.w-200 {
    width: 500px;
}
.ascDscIcons {
    position: relative;
    top: 7px;
    padding-left: 3px;
    cursor: pointer;
    i {
        position: absolute;
        font-size: 10px;
    }
}
.search-box {
    position: relative;
    width: 100%;
    input {
    background: #FAFAFA;
    border-radius: 8px;
    outline: none !important;
    border: none;
    padding: 15px 20px;
    width: 100%;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #696E79;
    padding-right: 50px;
}
i {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    color: rgba(6, 22, 36, 0.5);
    font-size: 17px;
}
}
.flex-container {
    display: flex;
    justify-content: end;
    .tableBtn {
        margin-right: 10px;
        display: flex;
        padding: 8px;
        border-radius: 5px;
        background: #FAFAFA;
        align-items: center;
        border: 1px solid #EAEAEA;
        cursor: pointer;
        span {
            color: #696E79;
            font-size: 12px;
            padding-left: 10px;
        }
    }
}
.pendingOrders {
    display: flex;
    align-items: center;
}
.pendingVal {
    align-items: center;
    display: flex;
}
.css-1guplmz-MuiButtonBase-root-MuiTab-root {
    text-transform:initial !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
}
.css-1guplmz-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #282C3F !important;
}
.css-1aquho2-MuiTabs-indicator {
    background-color: #282C3F !important;
    height: 1px !important;
}

.productImg {
    img {
        width: 60px;
        height: 60px;
        object-fit: contain;
    }
}


.customer-table-main .tableFooter {
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
}

.unshiped-filed {
    padding: 7px 20px;
    border-radius: 30px;
    font-size: 12px;
    background: rgba(255, 148, 26, 0.05);
    color: #FF941A;
}


.no-data-found-main {
    text-align: center;
    img {
        width: 200px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .no-found-text {
        font-size: 20px;
    }
}


.color-black {
    width: 60px !important;
    height: 60px !important;
    color: #080808 !important;
}


.loader-area-main {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    .loading-text {
        font-size: 13px;
        margin-top: 20px;
    }
}