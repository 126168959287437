.announce-table-main{
    .MuiPaper-elevation {
        box-shadow: none !important;
    }
}

.table-headding-part {
    color: #282C3F;
    font-weight: 600;
    font-size: 15px;
}

.row-area-main {
    cursor: pointer;
}

.main-announcemnet-details-box{
    padding: 20px;
    .heading-row {
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        color: #282C3F;
        margin-bottom: 10px;
    }
    .date-and-time {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #696E79;
        margin-bottom: 8px;
    }
    .mail-id {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 20px;
        color: #696E79;
    }
    .paragraph-text{
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #696E79;
            margin-bottom: 20px;
        }
    }
}

.add-announce-btn-main {
    text-align: right;
    margin-bottom: -30px;
    .comman-btn {
        display: inline-block;
        width: auto;
        margin-left: auto;
        margin-top: 10px;
        margin-bottom: -20px;
        position: relative;
        z-index: 99;
    }
}

.edit-icon-announce {
    background: #282C3F;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-top: -41px;
    cursor: pointer;
    img{
        filter: brightness(100);
    }
}


// ---Add-announcement---//

.modal-body-main-inner {
    padding: 30px 20px;
    padding-top: 0px;
    .form-field-main {
        margin-bottom: 20px;
        .comman-title {
            font-weight: 500;
            font-size: 16px;
            color: #282C3F;
            margin-bottom: 10px;
        }
        input {
            background: #ffff;
            border: 1px solid #ccc;
            box-shadow: none;
            border-radius: 7px;
        }
    }
}


.modal-body-main-inner .ck-editor .ck-editor__main .ck-content {
    height: 170px;
    font-size: 15px;
}

.input-file-upload-main {
    border: 1px solid #E0E0E0;
    padding: 30px 0px;
    text-align: center;
    position: relative;
    input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
    }
    .inner-box-main{
        .file-upload-icon{
            img {
                width: 35px;
                margin-bottom: 10px;
            }
        }
        .add-file-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #282C3F;
            margin-bottom: 10px;
        }
        .drage-file {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: #696E79;
        }
    }
}

.btn-row-main {
    text-align: center;
    .comman-btn {
        display: inline-block;
        width: auto;
        padding: 10px 40px;
        margin-bottom: 0;
    }
}

.modal-body-main-inner{
    .text-editor-main {
        textarea {
            height: 110px;
            resize: none;
            border-radius: 7px;
            border: 1px solid #ccc;
            font-family: 'Plus Jakarta Sans';
            font-size: 13px;
        }
    }
}


.imgActionIcon {
    z-index: 999;
    position: absolute;
    right: 10px;
    top: 10px;
    background: red;
    padding: 6px 10px;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
}