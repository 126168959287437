.no-data-found-main-faq {
    text-align: center;
    padding: 100px 0px;

    .inner-box-img {
        text-align: center;
        margin-bottom: 20px;

        img {
            width: 400px;
        }
    }

    .small-text-main {
        font-weight: 600;
        font-size: 22px;
        line-height: 22px;
        color: #212529;
        margin-bottom: 30px;
    }

    .btn-area-main {
        display: flex;
        background: #282C3F;
        justify-content: center;
        width: 250px;
        margin: auto;
        align-items: center;
        column-gap: 10px;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        padding: 17px 0px;
        cursor: pointer;
    }
}

.faq-row-main {
    justify-content: flex-end;
}

.faq-second-row {
    padding-bottom: 40px;
}


.title {
    font-size: 15px;
    line-height: 16px;
    color: #061624;
    padding: 0px 15px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    font-weight: 600;
    text-transform: capitalize;
}

.questions-page-main-first-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .right-side-main {
        display: flex;
        align-items: center;
        column-gap: 20px;
    }

    .left-side-main {
        .comman-title {
            margin-bottom: 0px;
            font-size: 20px;
        }
    }
}

.questions-answer-row {
    .innner-row-main {
        align-items: self-start;

        .left-side-col {
            width: 70%;
            display: flex;
            column-gap: 20px;

            .numbers-main {
                font-weight: 500;
                font-size: 17px;
                color: #282C3F;
            }

            .contents-main {
                .question-name {
                    font-weight: 500;
                    font-size: 18px;
                    color: #282C3F;
                    margin-bottom: 10px;
                }

                .question-answer {
                    font-weight: 400;
                    font-size: 15px;
                    color: #696E79;
                    line-height: 24px;
                }
            }
        }
    }
}

.inner-file-area input.input-file {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
}

.new-faq .text-new-arya {
    height: 70px;
    margin-top: 10px;
    border-radius: 8px;
}

.upload-file-component {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    text-align: center;
    padding: 40px 0px;
    position: relative;

    .inner-file-area {
        height: 100%;

        .upload-icon {
            margin-bottom: 10px;
            width: 32px;
        }

        .add-box-main {
            display: flex;
            justify-content: center;
            column-gap: 10px;
            margin-bottom: 10px;

            .add-file {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #282c3f;
                cursor: pointer;
                text-transform: lowercase;
            }

            .add-from-url {
                cursor: pointer;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #282c3f;
                text-decoration: underline;
                z-index: 9;
                position: relative;
            }
        }

        .accept-images-only {
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            color: #696e79;
        }
    }
}

.modal-potion {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%) !important;
    width: 600px !important;
    background-color: #fff;
    border: none;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    background: #FFFFFF !important;
    border-radius: 10px !important;
    padding: 0px;
}

.modal-hedding {
    position: relative;
    border-bottom: 0.578379px solid #696e7938;
    padding: 15px 0;
    border-bottom: 1pc solif black;
}

.modal-hedding h3 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: left;
    color: #1E1E1E;
    margin: 0;
    border-left: 6px solid #3f923c;
}

.close-faq button {
    background: transparent;
    border: none;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.faq-form {
    padding: 20px;
}

.faq-input-form .in-faq {
    padding-top: 10px;
}

.faq-input-form .new-faq input {
    margin-top: 10px;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #858585;
    border: 1px solid #E0E0E0;
    border-radius: 8px !important;
}

.faq-input-form .new-faq input {
    margin-top: 10px;
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    padding: 7px 10px;
    color: #858585;
    border: 1px solid #E0E0E0;
    border-radius: 8px !important;
}

.faq-input-form .new-faq select {
    width: 100%;
    margin-top: 10px;
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    padding: 7px 10px;
    color: #858585;
    border: 1px solid #E0E0E0;
    border-radius: 8px !important;
}



.faq-input-form .in-faq textarea {
    height: 80px;
    margin-top: 10px;
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #858585;
    resize: none;
    border: 1px solid #E0E0E0;
    border-radius: 8px !important;
}

.faq-input-form .in-faq label {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #1E1E1E;
}

.upload-img h3 {
    font-size: 13px;
    line-height: 22px;
}

.new-faq label {
    font-size: 13px;
    line-height: 22px;
}

.faq-input-form .faq-submit {
    text-align: end;
    padding-top: 25px;
}

.faq-input-form .faq-submit button {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    background: linear-gradient(90deg, #006838 0%, #8DC63F 100%);
    border-radius: 26px;
    padding: 13px 30px;
    cursor: pointer;
    border: none;
}