.user-setting-wrapper {
    min-height: 60vh;
    display: flex;

    .menu-box {
        border-right: 1px solid #85858533;

        .menu-item {
            width: max-content;
            min-width: 100%;
            cursor: pointer;
            border-bottom: 1px solid #85858533;

            img {
                margin-right: 1rem;
            }

            p {
                color: #858585;
                font-weight: 500;
                font-size: 17px;
            }

            padding: 1rem;
            display: flex;
            align-items: center;
        }

        .menu-item.active {
            p {
                color: #000000;
            }
        }
    }

    .profile-form-grid {
        max-width: 90%;
        width: 700px;
        margin: 3rem auto 0.5rem auto;
    }

    .content-wrapper {
        width: 100%;
        overflow: hidden;

        .content-box {
            text-align: center;

            img {
                width: 140px;
                height: 140px;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }

    .form-group {
        label {
            color: #1e1e1e;
            padding-bottom: 10px;
            display: block;
            font-weight: 500;
        }

        input {
            color: #858585;
        }

        margin: 15px;
        text-align: left;
        position: relative;
    }

    .form-action {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;

        button {
            cursor: pointer;
            // padding: 0.5rem 1rem;
            border-radius: 4px;
        }

        .save-btn {
            color: white;
            background: linear-gradient(90deg, #006838 0%, #8DC63F 100%);
            border-radius: 26px;
            border: 1px solid #006838;
            margin-left: 1rem;
            font-size: 16px;
        }

        .discard-btn {
            border: 1px solid #858585;
            background-color: transparent;
            font-size: 16px;
            border-radius: 26px;
        }
    }
}

.text-center-img {
    display: flex;
    justify-content: center;
}

.new-avtar {
    position: relative;
    max-width: 200px;
    display: flex;
    justify-content: center;
}

.all-preview {
    position: relative;
    border: 6px solid #F8F8F8;
    border-radius: 100%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.all-new {
    transition: 0.1s all ease;
    display: inline-block;
    background: linear-gradient(90deg, #006838 0%, #8DC63F 100%);
    border-radius: 24px;
    padding: 12px 58px !important;
    padding: 15px;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.user-profiles {
    text-align: left;
}

.user-profiles h3 {
    font-size: 25px;
    line-height: 45px;
    color: #000000;
    font-weight: 600;
}

.user-profiles .all-data p {
    padding-bottom: 12px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.6);
}

.upadet-btn-top {
    text-align: left;
}

.new-hedding-top {
    text-align: center;
    padding-top: 20px;
}

.new-hedding-top h3 {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #141413;
}