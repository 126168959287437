.sub-admin-tabs-main {
    padding: 5px 15px;
    .sub-admin-header-tabs-inner{
        .MuiTabs-root{
            .MuiTabs-flexContainer{
                .MuiTab-textColorPrimary {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: rgba(105, 110, 121, 0.5);
                    text-transform: capitalize;
                    padding: 0 !important;
                    .MuiTouchRipple-root {
                        display: none;
                    }
                }
                .Mui-selected{
                    color: #282C3F;
                }
            }
            .MuiTabs-indicator {
                background: #282C3F;
            }
        }
    }
}

.filter-row-pro-list {
    margin-bottom: 20px;
    display: flex;
    column-gap: 12px;
    margin-top: 20px;
    .left-side-box {
        width: 100%;
        position: relative;
        i{
            position: absolute;
            right: 16px;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #0616244d;
        }
        input {
            background: #FAFAFA;
            border-radius: 8px;
            border: none;
            padding: 14px 20px;
        }
    }
    .right-side-box{
        display: flex;
        align-items: center;
        column-gap: 12px;
        width: 10%;
        .comman-col{
            width: 100%;
            .comman-btn {
                width: 100%;
                text-align: center;
                justify-content: center;
                background: #FAFAFA;
                border: 1px solid #ccc;
                color: #696E79;
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 0px;
                padding: 12px 20px;
                border-radius: 4px !important;
            }
            .MuiOutlinedInput-root {
                width: 100%;
                font-size: 12px;
                background: #fafafa;
                border-radius: 8px;
                border: 1px solid #EAEAEA;
                .MuiSelect-select {
                    padding: 14px 20px;
                    text-align: center;
                    background: #fafafa;
                }
                input.MuiSelect-nativeInput{
                    border: none;
                }
                fieldset.MuiOutlinedInput-notchedOutline{
                    border: none;
                }
            }
        }
    }
}


.table-area-main-listing{
    table {
        width: 100%;
        border-spacing: 0;
        border: none;
        thead{
            tr{
                th{
                    text-align: left;
                    border-bottom: 1px solid #dbdde2;
                    padding: 10px 10px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    color: #282C3F;
                    .fa-sort{
                        cursor: pointer;
                    }
                 }
            }
        }
        tbody{
            tr{
                td {
                    border-bottom: 1px solid #dbdde2;
                    padding: 10px 10px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #696E79;
                }
                &:last-child{
                    td{
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

.actions-view{
    text-align: right;
}

.css-19kzrtu{
    padding: 0px !important;
}

.pending-tabs {
    background: rgba(255, 148, 26, 0.05);
    border-radius: 39.3298px;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    color: #FF941A;
    padding: 7px 2px;
}


.add-sub-admin-btn-main {
    margin-bottom: -50px;
    padding-right: 14px;
}

.roles-checked {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
}

.modal-body-main-inner .form-field-main{
    position: relative;
    .invalid-feedback {
        position: absolute;
        bottom: -15px;
        font-size: 12px;
        color: red;
        left: 0px;
    }
}
.modal-body-main-inner {
    height: auto !important;
}

.inner-box-sub-admin-Detail-main{
    .first-row-details-main {
        margin-bottom: 20px;
        border-bottom: 1px solid #efefefcc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        .left-side-name {
            font-weight: 600;
            font-size: 25px;
            text-transform: capitalize;
        }
    }
    .second-row-main {
        padding: 0px 20px;
        padding-bottom: 40px;
        .comman-row-main {
            display: flex;
            margin-bottom: 20px;
            .labal-main {
                width: 10%;
                min-width: 140px;
                font-weight: 500;
                font-size: 16px;
                color: #282C3F;
            }
            .labal-name {
                font-weight: 500;
                font-size: 15px;
                color: #696E79;
            }
        }
    }
    
    .third-row-main {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 20px;
        padding-top: 20px;
        border-top: 1px solid rgba(239, 239, 239, 0.8);
        button.comman-btn {
            display: inline-block;
            width: 140px;
        }
    }
}
@media (max-width: 850px) {
    .roles-checked {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}
