.login-page-main-area {
    height: 100vh;

    .inner-box-login {
        background-color: #fff;
        height: 100%;
        display: flex;

        .left-side-vector-area {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 80%;
            }

            @media (max-width: 991px) {
                display: none;
            }
        }

        .right-side-area-main {
            width: 50%;
            background: #fff;
            margin: 35px;
            display: flex;
            border-radius: 10px;
            align-items: center;
            justify-content: center;

            @media (max-width: 1400px) {
                margin: 20px;
            }

            @media (max-width: 991px) {
                margin: 70px;
                width: 100%;
            }

            @media (max-width: 767px) {
                margin: 20px 10px;
                width: 100%;
            }

            .inner-box-login-right-side {
                width: 600px;

                @media (max-width: 1500px) {
                    width: 400px;
                }

                @media (max-width: 1300px) {
                    width: 70%;
                }

                @media (max-width: 767px) {
                    width: 90%;
                }

                .logo-row {
                    margin-bottom: 15px;
                }

                .wel-text {
                    font-weight: 600;
                    font-size: 44px;
                    margin-bottom: 10px;

                    @media (max-width: 1500px) {
                        font-size: 35px;
                        margin-bottom: 10px;
                    }

                    @media (max-width: 1400px) {
                        font-size: 30px;
                        margin-bottom: 5px;
                    }
                }

                .small-des {
                    font-weight: 500;
                    font-size: 15px;
                    color: #696e79;
                    margin-bottom: 35px;

                    @media (max-width: 1500px) {
                        font-size: 14px;
                    }

                    @media (max-width: 1400px) {
                        margin-bottom: 25px;
                    }
                }

                .login-form-main {
                    .form-field {
                        margin-bottom: 30px;

                        @media (max-width: 1500px) {
                            margin-bottom: 25px;
                        }

                        position: relative;

                        .invalid-feedback {
                            position: absolute;
                            font-size: 11px;
                            color: red;
                            bottom: -16px;
                            left: 2px;
                            font-weight: 400;
                        }

                        input.is-invalid {
                            border: 1px solid red;
                        }

                        i {
                            position: absolute;
                            right: 15px;
                            margin: auto;
                            display: flex !important;
                            align-items: center;
                            justify-content: center;
                            top: 29px;
                            bottom: 0;
                            font-size: 16px;
                            cursor: pointer;
                            color: #696e79;
                        }
                    }

                    .need-account {
                        span {
                            color: #282c3f;
                            font-weight: 600;
                            cursor: pointer;
                        }

                        font-weight: 400;
                        font-size: 14px;
                        line-height: 14px;
                        color: #696e79;
                        font-family: "Plus Jakarta Sans";
                        text-align: center;
                    }

                    .forgot-row {
                        margin-top: -16px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 40px;

                        @media (max-width: 1500px) {
                            margin-bottom: 25px;
                        }

                        @media (max-width: 1500px) {
                            margin-top: -10px;
                        }

                        .left-side {
                            label {
                                color: #696e79;

                                .MuiCheckbox-root {
                                    padding-top: 0;
                                    padding-bottom: 0;
                                }

                                .MuiTypography-body1 {
                                    font-size: 14px;
                                }
                            }
                        }

                        .right-side {
                            font-weight: 500;
                            font-size: 14px;
                            color: #282c3f;
                            position: relative;
                            padding-top: 4px;

                            &:hover {
                                cursor: pointer;
                                text-decoration: underline;
                                color: #000;
                            }
                        }
                    }

                    .last-row-main {
                        .left-col {
                            .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 14px;
                                color: #696e79;
                                font-family: "Plus Jakarta Sans";
                            }
                        }
                    }

                    .login-btn {
                        .comman-btn {
                            justify-content: center;
                            border-radius: 0;
                            width: 100%;
                            justify-content: center;
                            border-radius: 26px !important;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}

.sign-up-page {
    width: 600px;
    margin: 60px auto;

    .email-id-recived-otp {
        color: #000;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .enter-otp {
        margin-bottom: 30px;
        letter-spacing: 0.05em;
        color: #636365;
        font-size: 17px;
        font-weight: 400;
    }

    .formfield-area-main {
        display: flex;
    }
}

.genderClass {
    display: contents !important;
}