.offer-btn-main {
    margin-bottom: -47px;
    margin-right: 18px;
}


.table-code {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: #282C3F;
    margin-bottom: 8px;
}

.table-code-des {
    font-weight: 400;
    font-size: 13px;
    text-transform: capitalize;
    color: #696E79;
}

.comman-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #696E79;
}

.amout-of {
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    color: #282C3F;
    margin-bottom: 8px;
}

.order-discount {
    font-weight: 400;
    font-size: 13px;
    text-transform: capitalize;
    color: #696E79;
}

.price-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #696E79;
}

.offer-active {
    background: rgba(5, 182, 81, 0.05);
    border-radius: 39.3298px;
    padding: 7px 10px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #05B651;
    text-transform: capitalize;
}

.inner-box-create-offer {
    padding: 20px 0px;
    display: flex;
    .left-side-box {
        padding: 0px 20px;
        width: 70%;
    }
    .right-side-box {
        width: 30%;
        padding: 0px 20px;
        border-left: 1px solid #ccc;
    }
}

.under-line-tile {
    position: relative;
    margin-bottom: 24px;
    .title {
        background: #fff;
        position: relative;
        padding-right: 10px;  
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        color: #282C3F;
        border: none;
        padding-left: 0;
        padding-right: 20px;
    }
}

.under-line-tile:before {
    position: absolute;
    content: '';
    background: #696E79;
    left: 0;
    right: 0;
    top: 5px;
    bottom: 0;
    height: 1px;
    margin: auto;
}


.comman-labal-main {
    font-weight: 500;
    font-size: 18px;
    color: #282C3F;
    margin-bottom: 15px;
}

.custom-radio-btn .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: #696E79;
}

.custom-radio-btn  .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #282C3F;
}

.custom-radio-btn {
    margin-bottom: 10px;
}

.comman-discount-code-main {
    display: flex;
    align-items: center;
    column-gap: 20px;
    .inner-input-box {
        width: 40%;
        input{
            width: 100%;
            border: 1px solid #E0E0E0;
            background: #fff;
            border-radius: 0px !important;
        }
    }
    .genrate-btn{
        .comman-btn {
            margin-bottom: 0px;
            padding: 12px 30px;
        }
    }
}

.text-customers {
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: #696E79;
    margin-top: 10px;
    margin-bottom: 30px;
}

.offer-code-genrated {
    flex-direction: row !important;
    margin-bottom: 25px;
    .MuiFormControlLabel-root{
        margin-right: 0px;
        position: relative;
        margin-left: 0;
        .MuiRadio-colorPrimary {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            padding: 0;
            opacity: 0;
            visibility: hidden;
        }
        .MuiFormControlLabel-label {
            padding: 10px 20px;
            border: 1px solid #ccc;
            box-sizing: border-box;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
        }
    }
}

.offer-code-genrated .MuiFormControlLabel-root .MuiRadio-colorPrimary.Mui-checked + .MuiFormControlLabel-label {
    color: #fff;
    background: #282C3F;
    border-color: #282C3F;
}

.input-box-for-percentage {
    width: 40%;
    margin-bottom: 20px;
    input {
        background: #fff;
        border-radius: 0 !important;
    }
}

.min-requirements-main {
    flex-direction: row !important;
    margin-bottom: 10px;
    width: 44%;
}


.min-purchase-ammount-input {
    width: 44%;
    margin-bottom: 30px;
    .inner-form-area {
        width: 54%;
        margin-left: auto;
        input {
            background: #fff;
            border-radius: 0 !important;
        }
    }
}

.checkbox-limit-number {
    flex-direction: row !important;
    margin-bottom: 10px;
    .MuiFormControlLabel-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #696E79;
    }
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #282C3F !important;
}

.second-limit-number{
    width: 100%;
    .inner-form-area{
        margin-left: 0;
        width: 30%;
    }
}

.start-dates-row-main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    margin-bottom: 20px;
    .form-fields-main{
        .comman-labal-main {
            margin-bottom: 10px;
            font-size: 15px;
        }
        .input-text-main{
            input{
                background: #fff;
                border-radius: 0 !important;
                padding: 10px 10px;
                height: 44px;
            }
        }
    }
}

.last-btns-row-main {
    padding: 20px;
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    .comman-btn {
        margin-bottom: 0px;
        padding: 14px 50px;
        border-radius: 5px !important;
        width: 120px;
    }
    .discard-btns {
        background: #FAFAFA;
        border: 1px solid #EAEAEA;
        color: #696E79;
    }
}

.right-side-summary-box {
    border: 1px solid #DADADA;
    border-radius: 10px;
    padding: 30px 20px;
    .title-main {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #282C3F;
        margin-bottom: 15px;
    }
    .page-des {
        margin-bottom: 30px;
    }
    .small-cased {
        margin-bottom: 10px;
    }
}
.w-100 {
    width: 100% !important;
}
.c-grey {
    color: #696E79;
}
.headerTabs {
    display: flex;
    justify-content: space-between;
    .comman-btn {
        margin-bottom: 0px !important;
    }
}
.minPurchase {
    display: flex !important;
    flex-direction: row !important;
}
.price-input {
    input {
    background-color: white !important;
    border-radius: 0px !important;}
}
.offer-btn {
    margin: 10px auto;
    width: 145px;
}
.inner-input-offer-box {
   input {
    background-color: white;
    border-radius:0px !important;
    width: 50%;
   }
}
.offerTitle {
    text-transform: capitalize; 
}
.responsive-w {
    max-width: 205px;
}
input.input-discountValue::placeholder {
    text-align: end;
}