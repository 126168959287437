.sidebar-main-area {
    position: fixed;
    width: 251px;
    top: 0;
    bottom: 0;
    transition: 0.1s all ease;

    .first-row-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 15px;

        .logo {
            cursor: pointer;
            display: block;
        }

        .hamburg {
            cursor: pointer;

            img {
                filter: invert(1);
            }
        }
    }

    .inner-menu-itm {
        margin-top: 20px;
        overflow-y: auto;
        max-height: calc(100vh - 101px);

        .menu-items-area {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                padding: 6px 0;
                position: relative;
                padding-right: 11px;

                .inner-block {
                    padding: 15px;
                    display: flex;
                    align-items: center;
                    column-gap: 20px;
                    font-weight: 500;
                    font-size: 17px;
                    cursor: pointer;
                    color: #000000;
                    transition: 0.1s all ease;

                    img {
                        width: 23px;
                        height: 23px;
                        object-fit: scale-down;
                        filter: brightness(0.5);
                    }

                    &:hover {
                        transition: 0.1s all ease;
                        background: linear-gradient(90deg, #006838 0%, #8DC63F 100%);
                        border-radius: 0px 26px 26px 0px;
                        color: #ffffff !important;

                    }

                    &:hover img {

                        filter: invert(1);
                    }
                }

                // .link-active {
                //     transition: 0.1s all ease;
                //     width: 100%;
                //     background: linear-gradient(90deg, #006838 0%, #8DC63F 100%);
                //     border-radius: 0px 26px 26px 0px;

                //     span {
                //         color: #ffffff !important;
                //     }

                //     img {
                //         filter: invert(1);
                //     }
                // }

                a.active {
                    transition: 0.1s all ease;
                    display: inline-block;
                    width: 100%;
                    background: linear-gradient(90deg, #006838 0%, #8DC63F 100%);

                    border-radius: 0px 26px 26px 0px;

                    span {
                        color: #ffffff !important;
                    }

                    img {
                        filter: invert(1);
                    }
                }
            }
        }
    }
}

.side-small {
    width: 70px;
    transition: 0.1s all ease;

    .first-row-logo {
        justify-content: center;

        .logo {
            display: none;
        }
    }

    .inner-menu-itm {
        .menu-items-area {
            li {
                .inner-block {
                    justify-content: center;

                    span {
                        display: none;
                    }
                }
            }
        }
    }
}

/* Track */
.sidebar-main-area .inner-menu-itm::-webkit-scrollbar {
    width: 6px;
}

.sidebar-main-area .inner-menu-itm::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.sidebar-main-area .inner-menu-itm::-webkit-scrollbar-thumb {
    background: #66ac3d80;
}

/* Handle on hover */
.sidebar-main-area .inner-menu-itm::-webkit-scrollbar-thumb:hover {
    background: #66ac3d80;
}

.count-task {
    position: absolute;
    top: 12px;
    left: 34px;
    background: #d1afaa;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
    color: #fffcfb;
}