.dashboard-black-boxed {
    display: grid;
    // grid-template-columns: repeat(5, 1fr);
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 31px;
    margin-bottom: 17px;

    .comman-col-main {
        background: #a84f3c;
        border-radius: 10px;
        display: flex;
        align-items: center;
        color: #Fff;
        padding: 20px 30px;
        column-gap: 20px;
    }

    .right-side-col {
        .count-text {
            font-weight: 800;
            font-size: 21px;
            color: #FFFFFF;
            margin-bottom: 5px;
        }

        .small-text {
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
        }
    }
}

.dropdown-row-main-dates {
    margin-bottom: 17px;

    .MuiButton-root {
        background: #fff;
        border: 1.40541px solid #EAEAEA;
        border-radius: 7.02703px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #282C3F;
        display: flex;
        align-items: center;
        column-gap: 6px;
        text-transform: capitalize;
        padding: 10px 15px;
        padding-right: 50px;
        position: relative;
    }
}

.dropdown-row-main-dates .MuiButton-root:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #918d8d;
    right: 14px;
}

.comman-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #282C3F;
    margin-bottom: 15px;
}

.totla-sales-chart-row {
    display: flex;
    grid-column-gap: 20px;
    margin-bottom: 20px;

    .comman-grid {
        background: #FFFFFF;
        border-radius: 9.93618px;
        padding: 15px 15px;
        width: 35%;

        &:last-child {
            width: 25%;
        }
    }
}

.com-row-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    &:last-child {
        margin-bottom: 0px;
    }

    .left-side {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #696E79;
    }

    .right-side {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #696E79;
    }
}

.title-rows-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .comman-title {
        margin-bottom: 0px;
    }

    .right-side-col-main {
        display: flex;
        align-items: center;
        column-gap: 10px;

        .view-reports {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-decoration-line: underline;
            color: #282C3F;
            cursor: pointer;
        }

        .dropdown-row-main-dates {
            margin-bottom: 0px;

            .MuiButton-root {
                padding: 6px 20px;
                padding-right: 30px;
                font-size: 12px;
                background: #FAFAFA !important;
                border: 1px solid #EAEAEA !important;
                border-radius: 5px !important;
            }
        }
    }
}

.price-row-main {
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    color: #696E79;
    margin-bottom: 20px;
}

.percentage-data {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #696E79;
    margin-bottom: 15px;
}

.conversion {
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: #282C3F;
    margin-bottom: 15px;
}


.com-row-field-second {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .first-col {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #282C3F;
        width: 50%;

        .session-main {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #696E79;
            margin-top: 7px;
        }
    }

    .second-col {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #282C3F;
    }

    .third-col {
        display: flex;
        font-size: 14px;
        width: 17%;
        column-gap: 12px;
        justify-content: center;

        img {
            width: 11px;
            margin-top: -19px;
            text-align: center;
        }
    }
}

.session-visitor {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.up-and-down-visitor {
    display: flex;
    align-items: center;
    column-gap: 10px;
    line-height: 21px;
    color: #05B651;
    font-size: 16px;

    img {
        width: 16px;
    }
}

.online-cod-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    margin-top: 20px;
    padding-bottom: 10px;
    border-top: 1px solid #ccc;
    padding-top: 20px;

    .com-cols {
        border-right: 1px solid #cccc;

        &:last-child {
            border-right: 0px !important;
        }

        .online {
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            color: #696E79;
            margin-bottom: 10px;
            position: relative;

            &::before {
                position: absolute;
                content: "";
                background: #1F72CD;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                left: -70px;
                right: 0;
                margin: auto;
                top: 5px;
            }
        }

        .numbers-of-online {
            font-weight: 700;
            font-size: 25px;
            line-height: 32px;
            letter-spacing: 0.02em;
            color: #282C3F;
        }

        .cod {
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            color: #696E79;
            margin-bottom: 10px;
            position: relative;

            &::before {
                position: absolute;
                content: "";
                background: #FF948D;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                left: -70px;
                right: 0;
                margin: auto;
                top: 5px;
            }
        }
    }
}


.Return-order-details-row {
    display: flex;
    grid-column-gap: 20px;
    margin-bottom: 20px;

    .comman-grid {
        width: 35%;
        background: #FFFFFF;
        border-radius: 9.93618px;
        padding: 15px 15px;
        width: 35%;

        &:nth-child(2) {
            width: 25%;
        }
    }
}

.Return-order-details-row .com-row-field-second .third-col img {
    margin-top: 0px;
}

.custom-return {
    .online {
        &::before {
            position: absolute;
            content: "";
            background: #FF948D !important;
            left: -120px !important;
        }
    }

    .cod {
        &::before {
            position: absolute;
            content: "";
            background: #282C3F !important;
            left: -146px !important;
        }
    }
}

.unit-by-sold-main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    margin-bottom: 20px;

    .comman-box {
        background: #fff;
        border-radius: 10px;
        padding: 20px;

        .heading-row-main {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            align-items: center;

            .comman-title {
                margin-bottom: 0px;
            }

            .view-report {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                text-decoration-line: underline;
                color: #282C3F;
                cursor: pointer;
            }
        }
    }
}


.order-return-chart-main {
    display: flex;
    align-items: center;

    .left-side {
        width: 70%;
    }

    .right-side {
        width: 30%;

        .comman-filed {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            column-gap: 13px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            text-transform: capitalize;
            color: #696E79;

            .round-grip {
                width: 10px;
                height: 10px;
                border-radius: 100%;
            }
        }
    }
}


.first-color {
    background: #FF9151;
}

.second-color {
    background: #6D6DBB;
}

.third-color {
    background: #FED419;
}

.fourth-color {
    background: #FF948D;
}

.fifth-color {
    background: #34BC42;
}

.sixth-color {
    background: #1F72CC;
}


@media (max-width:1500px) {
    .dashboard-black-boxed {
        grid-column-gap: 10px;
    }

    .dashboard-black-boxed .comman-col-main {
        padding: 15px 20px;
        border-radius: 5px;
    }

    .dashboard-black-boxed .comman-col-main .circle-main img {
        width: 50px;
    }

    .dashboard-black-boxed .right-side-col .count-text {
        font-size: 18px;
    }

    .dashboard-black-boxed .right-side-col .small-text {
        font-size: 14px;
    }

}