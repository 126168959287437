.btn-area-main {
    background: #282C3F;
    display: inline-block;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    font-size: 15px !important;
    padding: 11px 30px;
    margin-top: 30px;
    cursor: pointer;
}

.edit-del-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
    font-size: 19px;
}

.terms-box-main {
    padding: 30px 30px !important;
}

.comman-fields-main {
    margin-bottom: 15px;
    .com-title {
        font-weight: 500;
        font-size: 15px;
        color: #282C3F;
        margin-bottom: 10px;
    }
    input{
        background: #fff;
        border-radius: 0 !important;
        padding: 13px 20px;
    }
}

.comman-field-mains .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline{
    height: 500px !important;
    border: 1px solid #EEEEEE !important; 
    box-shadow: none !important;
}

.comman-field-mains .ck.ck-toolbar.ck-toolbar_grouping {
    border: 1px solid #eee !important;
}

.sav-btn-row {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    .comman-btn {
        width: 130px;
    }
}