.comission-box-main {
    padding: 20px 30px !important;
    .small-title {
        color: #282C3F;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .form-field {
        margin-bottom: 13px;
        position: relative;
        width: 40%;
        input{
            background: #fff;
            border-radius: 0 !important;
        }
        span {
            position: absolute;
            right: 13px;
            top: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            align-items: center;
            color: #696E79;
            font-size: 14px;
            font-weight: 600;
        }
    }
    .small-messs {
        color: #696E79;
        font-size: 14px;
        font-weight: 400;
    }
}