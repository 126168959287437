.cms-container {
    .cms-header {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            margin-bottom: 0;
        }

        .cms-btn-box {
            display: flex;
            align-items: center;

            .btn-one {
                margin-right: 1rem;
                padding: 15.2px 20px;
            }
        }
    }

    .cms-items {
        margin-top: 2.5rem;

        .cms-item {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #85858533;
            padding: 0.9rem 0;

            img {
                width: 7px;
                height: 14px;
            }

            p {
                margin-bottom: 0;
                font-size: 17px;
                font-weight: 500;
                color: #1e1e1e;
            }
        }
    }
}

// .cms-first-row-main {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     column-gap: 20px;
//     padding: 0px 20px;
//     .comman-btn {
//         margin-top: 20px;
//         width: auto;
//     }
// }

// .first-tile-head {
//     font-weight: 500;
//     font-size: 13px;
//     line-height: 16px;
//     color: #061624;
//     padding: 10px 20px;
//     border-bottom: 1px solid #ccc;
// }

// .cms-second-row-main{
//     .innner-row-main {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         padding: 20px 10px;
//         border-bottom: 1px solid #696E79;
//         cursor: pointer;
//         &:hover{
//             background: #f5f5f5;
//             transition: 0.1s all ease;
//         }
//         .left-side-col {
//             font-weight: 500;
//             font-size: 16px;
//             line-height: 20px;
//             text-transform: capitalize;
//             color: #696E79;
//         }
//         .right-side-col {
//             display: flex;
//             align-items: center;
//             column-gap: 20px;
//             font-size: 20px;
//             color: #282C3F;
//             i{
//                 cursor: pointer;
//             }
//         }
//     }
// }
// .cms-box-main {
//     padding-bottom: 60px !important;
// }

// .cms-second-row-main {
//     padding: 0px 20px;
// }


.nuber-faq .new-img-size {
    width: 60px;
    height: 60px;
    object-fit: cover;
}