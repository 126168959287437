// Rating

.rating-table-container {
    background-color: white;
}

.rating-product-img {
    width: 64px;
    border-radius: 4px;
}

.rating-title {
    font-size: 12px;
    color: #061624;
    line-height: 1.7;
    margin-right: 10px;
}

.rating-digitas {
    font-size: 12px;
}

// Review

.review-headar {
    display: flex;
    margin: 1rem;
    padding: 1rem 0;
}

.review-table-container {
    padding-top: 1px;
    background-color: #FFFFFF;
    margin: 15px;
    border-radius: 10px;
}

.product-img img {
    width: 194px;
}

.product-review-details {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
}

.product-review-details p {
    margin: 0;
    line-height: 1.5;
    display: flex;
    align-items: center;
}

.product-title {
    font-size: 40px;
    font-weight: 500;
    text-transform: uppercase;
}

.product-description {
    color: #696E79;
    font-size: 20px;
}

.product-rating-stars {
    color: #FFD51A;
}

.product-rating-blank-stars {
    color: #bbbbbb;
    margin-right: 10px;
}

.product-rating {
    color: #696E79;
    font-size: 14px;
}

.product-discount-price {
    font-size: 30px;
    font-weight: 600;
}

.product-actual-price {
    font-size: 21px;
    font-weight: 500;
    margin-left: 10px;
    color: #696E79;
    text-decoration: line-through;
}

.product-discount-rate {
    font-size: 15px;
    color: #FF5E00;
    margin-left: 10px;
}
@media (max-width:900px){
    .review-table-container {
        .product-img {
            img {
                width: 100%;
            }
        }
        .search-box {
            min-width: auto !important;
        }
        .product-review-details {
            padding-top: 10px;
            .product-title {
                font-size: 20px;
                .product-discount-price {
                    font-size: 20px; 
                }
                .product-actual-price {
                    font-size: 18px;
                }
                .product-discount-rate {
                    font-size: 14px;  
                }
            }
            .product-rating {
                font-size: 12px;
            }
            .product-description {
                font-size: 14px;
            }
        }
        .review-headar {
            flex-direction: column;
        }
    }
}
@media screen and (min-width:900px) and (max-width:1100px) {
    .review-table-container { 
        .product-review-details {
            .product-title {
                font-size: 30px;
                .product-discount-price {
                    font-size: 20px; 
                }
                .product-actual-price {
                    font-size: 18px;
                }
                .product-discount-rate {
                    font-size: 14px;  
                }
            }  
        }
    } 
}